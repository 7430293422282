<template>
    <div>
      <!-- IMAGE -->
      <v-dialog v-model="dialogImage" max-width="850px" width="90%"  :loading="loadingImage" persistent style="z-index:2001;">
        <DialogImageView
          :url="imageUrl" 
          :loading="loadingImage"
          :name="viewName"
          v-on:closeEmit="close"
        />
      </v-dialog>

      <!-- PDF -->
      <v-dialog v-model="dialogPDF" max-width="700px" :loading="loadingPDF" persistent style="z-index:2001;">
        <DialogPDFView
          :url="pdfUrl" 
          :loading="loadingPDF"
          :name="viewName"
          v-on:closeEmit="close"
        />
      </v-dialog>

      <!-- MOVIE -->
      <v-dialog v-model="dialogVideo" max-width="700px" :loading="loadingPDF" persistent style="z-index:2001;">
        <DialogVideoView
          :url="videoUrl" 
          :loading="loadingVideo"
          :name="viewName"
          v-on:closeEmit="close"
        />
      </v-dialog>

      <!-- 3DModel -->
      <v-dialog v-model="dialog3D"   persistent 
        :height="($vuetify.breakpoint.sm || $vuetify.breakpoint.xs)?'100vh':'100vh'"  
        :width="($vuetify.breakpoint.sm || $vuetify.breakpoint.xs)?'100vw':'100vw'"
        :fullscreen="true"
        style="z-index:2001;"  >
        <DModel
          :modelUrl="modelUrl" 
          :loading="loading3D"
          :height="($vuetify.breakpoint.sm || $vuetify.breakpoint.xs)?'100vh':'100vh'"  
          v-on:closeEmit="close"
        />
      </v-dialog>

      <!-- sound control snackbar -->
      <v-snackbar
        v-model="cardSound"
        color="white"
        :width="$vuetify.breakpoint.mdAndDown? '100%' :'70%'"
        max-width="2000px"
        timeout="-1"
        class="ma-0 pa-0"
        hide-details
        dense
      >
        <template>
          <v-container class="mt-2 mx-2 pa-0 ">
            <v-row align="center" width="100%">
              <v-col class="ma-0  pa-0 "  cols=10 sm=3 md=3 lg=3 xl=3 >
                <p  class="py-auto my-auto ml-4 black--text"> {{this.viewName}} </p>
              </v-col>
              <v-col class="ma-0 pa-0"  cols=12 sm=6 md=6 lg=6 xl=6 :order="($vuetify.breakpoint.xs)?'last':''" >
                <audio style="width:100%;" :src="soundUrl" controls controlslist="nodownload" id="audio-view"></audio>
              </v-col>
              <v-col class="ma-0 pr-4 py-0 text-end"  cols=1 sm=3 md=3 lg=3 xl=3  >
                <v-icon color="black" @click="stopSound">{{icons.close}}</v-icon>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </v-snackbar>

    </div>
      

</template> 

<script>
import axios from "axios"
import { mdiClose } from '@mdi/js';
const DialogPDFView = () => import('@/components/DialogPDFView.vue');
const DialogImageView = () => import('@/components/DialogImageView.vue');
const DialogVideoView = () => import('@/components/DialogVideoView.vue');
const DModel = () => import('@/components/3DModel.vue');

export default {
  name: 'DialogsShowContents',
  components :{ 
    DialogImageView,
    DialogPDFView,
    DialogVideoView,
    DModel,
  },

  data () {
    return {
      icons:{
        "close":mdiClose
      },
      loadingPDF:false,
      loadingImage:false,
      loadingVideo:false,
      loading3D:false,
      dialogImage:false,
      dialogPDF:false,
      dialogVideo:false,
      dialog3D:false,
      cardSound:false,
      soundUrl:null,
      soundId:null,
      videoUrl:null,
      imageUrl:null,
      pdfUrl:null,
      modelUrl:null,
      viewName:null,
    }
  },


  methods: {

    downloadContent(item,show){
      this.soundId=(item.contentType=="sound"&show) ? item.soundId : null;
      this.cardSound=(item.contentType=="sound") ? show : false;
      this.dialogPDF =(item.contentType=="pdf") ? show : false;
      this.loadingPDF= (item.contentType=="pdf") ? show : false;
      this.dialogVideo =(item.contentType=="movie") ?show : false;
      this.dialog3D =(item.contentType=="object") ? show : false;
      this.dialogImage =(item.contentType=="image") ? show : false;
      this.loading3D= (item.contentType=="object") ? show : false;
      this.loadingImage= (item.contentType=="image") ? show : false;
      this.viewName = item.fileName;
      axios({
        method: "GET",
        url:`${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/${item.contentType}s/${item[`${item.contentType}Id`]}`,
        headers: {"X-Api-Key": process.env.VUE_APP_KEY,"SessionId": this.$store.state.auth.login.sessionId,"Content-Type":"application/json" },
      }).then((response) => {
        let s3Url = response.data.results;
        if(show && item.contentType=="object") this.show3DModel(s3Url)
        else if(show && item.contentType=="image") this.showImage(s3Url)
        else if(show && item.contentType=="movie") this.showVideo(s3Url)
        else if(show && item.contentType=="sound") this.playSound(s3Url)
        else if(show) this.showPDF(s3Url)
        else{
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.href = s3Url;
          a.download = item.fileName;
          a.click();
          a.remove();
        }


      }).catch(reason => {
        console.log(reason.response)
        this.isError = true;
      });
    },

    
    //PDFダイアログの表示
    showPDF(url){
      axios({
      url: url,
      method: 'GET',
      responseType: 'blob'
    })
      .then(
        response => {
          let mimeType = 'application/pdf'
          let blob = new Blob([response.data], { type: mimeType })
          let k = window.URL.createObjectURL(blob);
          this.pdfUrl = k;
          if(this.$vuetify.breakpoint.xs) window.open(k);
          this.loadingPDF=false;
        })
      .catch(
        err => {
          this.loadingPDF=false;
          console.log(err)
        }
      )
    },

    //画像表示のダイアログの表示
    showImage(url){
      this.imageUrl = url;
      this.loadingImage=false;
    },


    //動画再生のダイアログの表示
    showVideo(url){
      this.videoUrl = url;
      this.loadingVideo=false;
    },

    //3Dモデルダイアログの表示
    show3DModel(url){
      this.modelUrl = url;
      this.loading3D = false;
      // console.log(url)
    },

    //mp3のURLの再生設定
    playSound(url){
      // console.log(url)
      // this.reChart();
      this.soundUrl = url;

      setTimeout(() => {
      let audio = document.getElementById("audio-view");
      // console.log(audio)
      if(audio){
        audio.currentTime = 0;
        audio.play();
      }
      }, 300);

    },

    //mp3の再生の中止
    stopSound(){
      this.soundId = null;
      let audio = document.getElementById("audio-view");
      // console.log(audio)
      if(audio){
        audio.pause();
      }
      this.cardSound=null;
    },

    close() {
      this.dialog3D = false;
      this.dialogImage = false;
      this.dialogPDF = false;
      this.dialogVideo = false;
      this.isReplace = false;
      this.imageUrl = null;
      this.cardSound=null;
    },
  
  },
}

</script>
